/***********************************************
 * panel-group默认参数
 ***********************************************/
export const panel_group_default = {
    group_id: null,
    group_name: "",
    group_uuid: "",
    group_auto_refresh_active: false,
    group_auto_refresh_interval: 3600,
    group_time_query_active: false,
    group_time_query_type: 2,
    group_time_query_start: "",
    group_time_query_end: "",
    group_time_query_length: "30",
    group_time_query_unit: "days",
    panel_layout_list: [],
    panel_list: [],
};
/***********************************************
 * 查询的默认配置参数
 ***********************************************/
export const query_default = {
    query_id: null,
    query_name: "",
    query_type: 1,
    datasource_id: null,
    table: "",
    fields: "",
    time_query_field: "",
    time_query_type: 3,
    time_query_start: "",
    time_query_end: "",
    time_query_length: "3",
    time_query_unit: "days",
    time_query_range: "today",
    //其他查询的条件数组
    query_map: [],
};
/***********************************************
 * 单个panel的默认配置参数
 ***********************************************/
export const panel_default = {
    panel_id: null,
    panel_name: "",
    type: "g2-mix",
    //数据查询的相关配置
    query_id: null,
    //options因所选组件不同，配置参数也不同
    options_type: 3,
    options: {},
};
//查询产生的临时数据
export const query_temp_data = {
    query_id: null,
    fields: [],
    temp_data: [],
    total: 0,
    sql_explain: {
        sql_str: "",
        sql_time_start: "0000-00-00 00:00:00",
        sql_time_end: "0000-00-00 00:00:00",
        sql_time_used: 0,
        table: "",
        table_is_view: true,
    },
    loading: false,
};
/***********************************************
 * 顶部全局参数
 ***********************************************/
export const group_topbar = {
    daterange: {
        modal: false,
        active: false,
        type: 1,
        start: "2020-01-01 00:00:00",
        end: "2020-01-01 12:00:00",
        length: "3",
        unit: "天",
    },
    locked: false,
};
/***********************************************
 * 数据源的默认配置参数
 ***********************************************/
export const datasource_settings_default = {
    datasource_id: null,
    datasource_name: "新数据源",
    type: "mysql",
    hostname: "",
    database: "",
    username: "",
    password: "",
};

//添加的单个查询条件
export const query_map_item = {
    field: "",
    operator: "",
    value: "",
};

/***********************************************
 * 侧边栏参数
 ***********************************************/
export const group_sidebar = {
    show: false,
};
/***********************************************
 * 弹窗配置相关参数
 ***********************************************/
export const panel_modal = {
    //组件配置tab页
    ...panel_default,
};
export const query_type_list = [
    {
        text: "数据库",
        value: 1,
    },
    {
        text: "API",
        value: 2,
    },
    {
        text: "数据文件（csv, excel）",
        value: 3,
    },
];
export const time_query_unit_list = [
    {
        text: "秒",
        value: "seconds",
    },
    {
        text: "分钟",
        value: "minutes",
    },
    {
        text: "小时",
        value: "hours",
    },
    {
        text: "天",
        value: "days",
    },
    {
        text: "月",
        value: "months",
    },
    {
        text: "年",
        value: "years",
    },
];
export const time_query_range_list = [
    {
        text: "今日",
        value: "today",
    },
    {
        text: "昨日",
        value: "yesterday",
    },
    {
        text: "本周",
        value: "week",
    },
    {
        text: "上周",
        value: "last week",
    },
    {
        text: "本月",
        value: "month",
    },
    {
        text: "上月",
        value: "last month",
    },
    {
        text: "今年",
        value: "year",
    },
    {
        text: "去年",
        value: "last year",
    },
];
// 组件类型列表
export const panel_type_list = [
    {
        text: "图表",
        value: "g2-mix",
        icon: "PieChartIcon",
        query: true,
        options: {
            legend: true,
            slider: true,
            plots: [
                {
                    type: "line",
                    options: {
                        xField: "time",
                        yField: "value",
                        isStack: false,
                        seriesField: "eqname",
                        groupField: "eqname",
                        meta: {time: []},
                        annotations: [],
                    },
                },
            ],
        },
    },
    {
        text: "表格",
        value: "table",
        icon: "LayoutIcon",
        query: true,
        options: {
            option: {
                small: true,
            },
            scroll: 100,
            alert: {
                active: false,
                field: "value",
                lvl_1: 50,
                lvl_2: 100,
                lvl_3: 300,
                lvl_4: 500,
            },
        },
    },
    {
        text: "滚动提示",
        value: "scroll",
        icon: "ListIcon",
        query: true,
        options: {
            field: "",
            speed: 100,
            range: {
                info_start: 0,
                info_end: 0,
                warning_start: 0,
                warning_end: 0,
            },
        },
    },
    {
        text: "外链",
        value: "embed",
        icon: "LinkIcon",
        query: false,
        options: {
            type: "embed",
            src: "",
            aspect: "",
        },
    },
    {
        text: "展示参数",
        value: "params",
        icon: "InfoIcon",
        query: true,
        options: {
            type: "virtual",
            title: "title",
            text: "text",
            icon: "barChartIcon",
            color: "info",
            field: "",
            data: null,
        },
    },
    {
        text: "轮播图",
        value: "carousel",
        icon: "ImageIcon",
        query: false,
        options: {
            list: [],
            options: {},
        },
    },
    {
        text: "编程",
        value: "programming",
        icon: "CodeIcon",
        query: false,
        options: {
            list: [],
            options: {},
        },
    },
];
// 组件比较符列表
export const panel_operator_list = [
    {
        text: "=",
        value: "=",
    },
    {
        text: ">",
        value: ">",
    },
    {
        text: ">=",
        value: ">=",
    },
    {
        text: "<=",
        value: "<=",
    },
    {
        text: "<>",
        value: "<>",
    },
    {
        text: "LIKE",
        value: "LIKE",
    },
    {
        text: "NOT LIKE",
        value: "NOT LIKE",
    },
    {
        text: "BETWEEN",
        value: "BETWEEN",
    },
    {
        text: "NOT BETWEEN",
        value: "NOT BETWEEN",
    },
    {
        text: "IN",
        value: "IN",
    },
    {
        text: "NOT IN",
        value: "NOT IN",
    },
    {
        text: "NULL",
        value: "NULL",
    },
    {
        text: "NOT NULL",
        value: "NOT NULL",
    },
    {
        text: "SQL",
        value: "EXP",
    },
];
