import useJwt from "@/libs/auth/jwt/useJwt";

const axios = useJwt.axiosIns;

export const getProjectList = (params) => axios.get("/api/project/list/", params);
export const getProjectDetail = (id) => axios.get(`/api/project/info/${id}/`);
export const getProjectSystemDetail = (id) => axios.get(`/api/project/system_info/${id}/`);

export const updateProjectInfo = (data) => axios.patch(`/api/project/edit/${data.project_id}/`, data);
export const addProjectInfo = (data) => axios.post(`/api/project/add/`, data);
export const deleteProject = (id) => axios.delete(`/api/project/delete/${id}/`);

export const getProjectEnergy = (id) => axios.get(`/api/project/energy/${id}/`);
export const getProjectParams = (id) => axios.get(`/api/project/params/${id}/`);
export const getProjectOperation = (id) => axios.get(`/api/project/operation/${id}/`);
export const getProjectOptimization = (id) => axios.get(`/api/project/optimization/${id}/`);
