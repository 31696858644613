import { addProjectInfo, getProjectDetail,getProjectSystemDetail, getProjectEnergy, getProjectList, getProjectOperation, getProjectOptimization, getProjectParams, updateProjectInfo } from "@/api/projects";
import { assignedProject, changeUserRole, deleteProject, getRoleList, getUserList } from "@/api/users";

export default {
	namespaced: true,
	state: {
		selected: {},
	},
	getters: {},
	mutations: {
		UPDATE_PROJECT_SELECTED(state, val) {
			state["selected"] = val;
		},
	},
	actions: {
		fetchProjects(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				getProjectList({ params: queryParams })
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		fetchProject(ctx, id) {
			return new Promise((resolve, reject) => {
				getProjectDetail(id)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		fetchProjectSystemInfo(ctx, id) {
			return new Promise((resolve, reject) => {
				getProjectSystemDetail(id)
						.then((response) => resolve(response))
						.catch((error) => reject(error));
			});
		},
		fetchProjectEnergy(ctx, id) {
			return new Promise((resolve, reject) => {
				getProjectEnergy(id)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		fetchProjectParams(ctx, id) {
			return new Promise((resolve, reject) => {
				getProjectParams(id)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		fetchProjectOperation(ctx, id) {
			return new Promise((resolve, reject) => {
				getProjectOperation(id)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		fetchProjectOptimization(ctx, id) {
			return new Promise((resolve, reject) => {
				getProjectOptimization(id)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		GetUsers(ctx) {
			return new Promise((resolve, reject) => {
				getUserList()
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		addProject(ctx, data) {
			return new Promise((resolve, reject) => {
				addProjectInfo(data)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		updateProject(ctx, data) {
			return new Promise((resolve, reject) => {
				updateProjectInfo(data)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		// deleteProject(ctx, id) {
		//   return new Promise((resolve, reject) => {
		//     deleteProject(id)
		//       .then((response) => resolve(response))
		//       .catch((error) => reject(error));
		//   })
		// },
		delteUserInfo(ctx, id) {
			return new Promise((resolve, reject) => {
				deleteProject(id)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		getRole(ctx, data) {
			return new Promise((resolve, reject) => {
				getRoleList(data)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		changeRole(ctx, data) {
			return new Promise((resolve, reject) => {
				changeUserRole(data)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		assignProject(ctx, data) {
			return new Promise((resolve, reject) => {
				assignedProject(data)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
	},
};
