<template>
  <div id="app" :class="[skinClasses]" class="h-100">
    <component :is="layout">
      <router-view/>
    </component>

    <scroll-to-top v-if="enableScrollToTop"/>
  </div>
</template>

<script>
import ScrollToTop from "@core/components/scroll-to-top/ScrollToTop.vue";

// This will be populated in `beforeCreate` hook
import {$themeBreakpoints, $themeColors, $themeConfig} from "@themeConfig";
import {provideToast} from "vue-toastification/composition";
import {watch} from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";

import {useCssVar, useWindowSize} from "@vueuse/core";

import store from "@/store";

const LayoutVertical = () => import("@/libs/layouts/vertical/LayoutVertical.vue");
const LayoutHorizontal = () => import("@/libs/layouts/horizontal/LayoutHorizontal.vue");
const LayoutFull = () => import("@/libs/layouts/full/LayoutFull.vue");
export default {
  components: {
    // Layouts,
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    ScrollToTop,
  },

  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === "full") return "layout-full";
      return `layout-${this.contentLayoutType}`; // 当前是layout-vertical
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ["primary", "success", "info", "warning", "danger", "secondary", "light", "dark"];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ["xs", "sm", "md", "lg", "xl"];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2));
    }

    // Set RTL
    const {isRTL} = $themeConfig.layout;
    document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
  },
  setup() {
    const {skin, skinClasses} = useAppConfig();
    const {enableScrollToTop} = $themeConfig.layout;

    // If skin is dark when initialized => Add class to body
    if (skin.value === "dark") document.body.classList.add("dark-layout");

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: false,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 5000,
      transition: "Vue-Toastification__fade",
    });
    // Set Window Width in store
    store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth);
    const {width: windowWidth} = useWindowSize();
    watch(windowWidth, (val) => {
      store.commit("app/UPDATE_WINDOW_WIDTH", val);
    });
    return {
      skinClasses,
      enableScrollToTop,
    };
  },
};
</script>

<style lang="scss">
.adsk-viewing-viewer {
  background: none;
}

.homeViewWrapper {
  display: none;
}

@font-face {
  font-family: HYLingXin;
  src: url(https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/fonts/HYLingXin.ttf);
}

@font-face {
  font-family: LcdD;
  src: url(https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/fonts/LcdD.ttf);
}

@font-face {
  font-family: HuXiaoBoNanShenTi;
  src: url(https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/fonts/HuXiaoBoNanShenTi-2.otf);
}

.font-title {
  font-family: HYLingXin, sans-serif;
}

.font-digit {
  font-family: LcdD, sans-serif;
}

/* 内容不超出浏览器窗口时不显示滚动条 */
//html {
//  overflow: auto;
//}

/*!* 美化滚动条样式 *!*/
::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

//::-webkit-scrollbar-track {
//  background: rgba(16, 31, 28, 0);
//  width: 6px;
//}

::-webkit-scrollbar-track:hover {
  background: rgba(16, 31, 28, 0.7);
  -moz-border-radius: 2em;
  -webkit-border-radius: 2em;
  border-radius: 2em;
  width: 6px;
}

//::-webkit-scrollbar-thumb {
//  background-color: rgba(144, 147, 153, 0);
//}

::-webkit-scrollbar-thumb:vertical {
  background-color: rgba(144, 147, 153, 0.7);
  -webkit-border-radius: 4px;
  border-radius: 4px;
  //background-image: linear-gradient(transparent, white, transparent);
}

::-webkit-scrollbar-thumb:horizontal {
  background-color: rgba(144, 147, 153, 0.7);
  -webkit-border-radius: 4px;
  border-radius: 4px;
  //background-image: linear-gradient(90deg, transparent, white, transparent);
}

::-webkit-scrollbar-thumb:hover {
  background-clip: padding-box;
  background-color: rgba(144, 147, 153, 1);
  -moz-border-radius: 2em;
  -webkit-border-radius: 2em;
  border-radius: 2em;
  cursor: pointer;
  min-height: 28px;
  transition: background-color 0.3s;
}

/*深色主题下树形组件字体颜色*/
.ztree li {
  line-height: 2rem;
}

.dark-layout .ztree li a {
  color: #b4b7bd;
}
</style>
